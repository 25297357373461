import React, { Suspense } from "react";
import { Container, Col, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import "../styles/search.css";
import axios from "axios";
import _debounce from "lodash.debounce";
import searchDescriptionData from "../data/searchDescriptionData";
import { Header, Footer } from "components";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: {},
      searchVal:
        typeof sessionStorage !== "undefined" &&
        sessionStorage.getItem("ss360Query")
          ? sessionStorage.getItem("ss360Query")
          : "",
      offset: 0,
      sortOption: "Most Relevant",
      sortOrder: "DESC",
      searchDescriptionData: searchDescriptionData,
      disableSearch: false,
      paginationNum:
        typeof sessionStorage !== "undefined" &&
        sessionStorage.getItem("pageNum")
          ? parseInt(sessionStorage.getItem("pageNum"))
          : 1,
      paginationCount: 0,
      ShowLoading: true,
    };
    this.fetchSearchResult = this.fetchSearchResult.bind(this);
    this.searchData = this.searchData.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.loadMoreResults = this.loadMoreResults.bind(this);
    this.sortTheResults = this.sortTheResults.bind(this);
    this.debounceForSuggestions = this.debounceForSuggestions.bind(this);
    this.fetchLinkDescription = this.fetchLinkDescription.bind(this);
    this.getPaginationCount = this.getPaginationCount.bind(this);
  }

  componentDidMount() {
    this.fetchSearchResult(this.state.searchVal, this.state.offset);
  }

  fetchSearchResult(searchTerm, offset, sortOpt, sortOrder) {
    let requestObject = {
      query: searchTerm,
      site: "www.gspann.com",
      offset: offset,
      limitPerGroup: true,
      includeContent: true,
      sort: sortOpt,
      sortOrder: sortOrder,
    };

    axios
      .get(`https://api.sitesearch360.com/sites`, { params: requestObject })
      .then((res) => {
        this.setState({
          searchData: res.data,
          ShowLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  searchData(e) {
    if (this.state.searchVal.length >= 3) {
      this.debounceForSuggestions();
      this.setState({ disableSearch: false });
    } else if (this.state.searchVal.length < 3) {
      this.setState({ disableSearch: true });
    }
  }

  handleSearchEnter(event) {
    if (this.state.searchVal && this.state.searchVal.length >= 3) {
      this.debounceForSuggestions();
    }
  }

  loadMoreResults(e) {
    let paginationNum = this.state.paginationNum;
    if (e.target.name === "previous") {
      paginationNum = paginationNum - 1;
    } else {
      paginationNum = paginationNum + 1;
    }
    this.setState({ paginationNum: paginationNum });
    sessionStorage.setItem("pageNum", paginationNum);
    let newOffset = (paginationNum - 1) * 10;
    this.setState({ offset: newOffset });
    this.fetchSearchResult(
      this.state.searchVal,
      newOffset,
      this.state.sortOption,
      this.state.sortOrder,
      e.target.name
    );
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  createMarkup(kvtable) {
    return { __html: `${item.kvtable}` };
  }

  sortTheResults(sortType) {
    let sortedData = this.state.searchData;
    if (sortType === "Most Relevant") {
      for (var key in sortedData.suggests) {
        sortedData.suggests[key].sort((data1, data2) => {
          return data2.relevance - data1.relevance;
        });
      }
    } else if (sortType === "Most Recent") {
      for (var key in sortedData.suggests) {
        sortedData.suggests[key].sort((data1, data2) => {
          let dateForData1 = null;
          let dateForData2 = null;
          if (data1 && data1.dataPoints) {
            dateForData1 = data1.dataPoints.filter(
              (item) => item.key === "Date:"
            );
            if (dateForData1 && dateForData1.length > 0) {
              dateForData1 = dateForData1[0].value;
            }
          }
          if (data2 && data2.dataPoints) {
            dateForData2 = data2.dataPoints.filter(
              (item) => item.key === "Date:"
            );
            if (dateForData2 && dateForData2.length > 0) {
              dateForData2 = dateForData2[0].value;
            }
          }

          if (dateForData1 && dateForData2) {
            return (
              new Date(dateForData2).getTime() -
              new Date(dateForData1).getTime()
            );
          }
        });
      }
    }
    this.setState({
      sortOption: sortType,
      sortOrder: "DESC",
      searchData: sortedData,
    });
  }

  debounceForSuggestions = _debounce(() => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      sessionStorage.setItem("ss360Query", this.state.searchVal);
      this.setState({ paginationNum: 1, offset: 0 });
      this.fetchSearchResult(
        this.state.searchVal,
        0,
        this.state.sortOption,
        this.state.sortOrder
      );
    }, 200);
  }, 100);

  fetchLinkDescription(description, link) {
    if (description) {
      return description;
    } else {
      let descriptionStr = "";
      let descriptionObj = searchDescriptionData.filter((item) => {
        if (item.url === link) {
          return item;
        }
      });
      if (descriptionObj && descriptionObj.length > 0) {
        descriptionStr = descriptionObj[0].content; //replace with your string.
        descriptionStr = `${descriptionStr.substr(
          0,
          Math.min(descriptionStr.length, descriptionStr.lastIndexOf(" "))
        )}...`;
      }

      return descriptionStr;
    }
  }
  handlePagination(e) {
    if (e.target.innerText !== "...") {
      sessionStorage.setItem("pageNum", parseInt(e.target.innerText));
      let newOffset = (parseInt(e.target.innerText) - 1) * 10;
      this.setState({
        offset: newOffset,
        paginationNum: parseInt(e.target.innerText),
      });
      this.fetchSearchResult(
        this.state.searchVal,
        newOffset,
        this.state.sortOption,
        this.state.sortOrder
      );
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  getPaginationCount() {
    if (
      this.state.searchData.totalResultsPerContentGroup &&
      Object.keys(this.state.searchData.totalResultsPerContentGroup).length > 0
    ) {
      let highestKey = Object.keys(
        this.state.searchData.totalResultsPerContentGroup
      ).reduce((key1, key2) =>
        this.state.searchData.totalResultsPerContentGroup[key1] >
        this.state.searchData.totalResultsPerContentGroup[key2]
          ? key1
          : key2
      );
      let paginationNum = Math.ceil(
        this.state.searchData.totalResultsPerContentGroup[highestKey] / 10
      );
      this.setState({ paginationCount: paginationNum });
    }
  }
  pagination(c, m) {
    var delta = 1,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);
    for (let i = c - delta; i <= c + delta; i++) {
      if (i < m && i > 1) {
        range.push(i);
      }
    }
    range.push(m);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  get searchSchema() {
      const { search= "",  href = "" } = typeof window === "undefined" ? {} : window?.location?.search
      const params = new URLSearchParams(search);
      const searchTerm = params.get("q");
      return <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://www.gspann.com",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "${href}"
            },
            "query-input": "required name=${searchTerm}"
          }
        }
      `}
    </script>
  }

  render() {
    var isWindow = true; //typeof window !== "undefined";
    const {
      prismicHeromenu,
      prismicFooter,
      allPrismicPage,
      allPrismicResource,
    } = this.props.data;
    let pageURL = this.props.location.href;

    let result = [];

    for (var key in this.state.searchData.suggests) {
      let content = (
        <div className="contentGroupContainer" key={key}>
          <div className="cententGroupHeading">{key}</div>
          {this.state.searchData.suggests[key].map((item) => {
            item.kvtable = item.kvtable.replace(
              new RegExp("CASE STUDIES", "igm"),
              "CASE STUDY"
            );
            item.kvtable = item.kvtable.replace(
              new RegExp("WHITE PAPERS", "igm"),
              "WHITE PAPER"
            );

            return (
              <div className="searchItemContainer" key={item.name}>
                <div
                  className="contentGroup"
                  dangerouslySetInnerHTML={{ __html: item.kvtable }}
                ></div>
                <a href={item.link} className="searchHeading" hrefLang="en">
                  {item.name}
                </a>
                <div className="linkDescription">
                  {this.fetchLinkDescription(item.content, item.link)}
                </div>
              </div>
            );
          })}
          <div></div>
        </div>
      );
      result.push(content);
    }
    let paginationNum = 1;
    let paginationSection = null;
    if (
      this.state.searchData.totalResultsPerContentGroup &&
      Object.keys(this.state.searchData.totalResultsPerContentGroup).length > 0
    ) {
      let highestKey = Object.keys(
        this.state.searchData.totalResultsPerContentGroup
      ).reduce((key1, key2) =>
        this.state.searchData.totalResultsPerContentGroup[key1] >
        this.state.searchData.totalResultsPerContentGroup[key2]
          ? key1
          : key2
      );
      paginationNum = Math.ceil(
        this.state.searchData.totalResultsPerContentGroup[highestKey] / 10
      );
      let paginationContent = [];
      let paginationArr = this.pagination(
        this.state.paginationNum,
        paginationNum
      );
      if (paginationNum < 4) {
        for (let i = 1; i <= paginationNum; i++) {
          paginationContent.push(
            <div
              key={i}
              className={`${
                this.state.paginationNum === i
                  ? "pagination-number selected-pagination-number"
                  : i !== "..."
                  ? "pagination-number"
                  : "pagination-dots"
              }`}
              onClick={(e) => this.handlePagination(e)}
            >
              {i}
            </div>
          );
        }
      } else {
        paginationContent = paginationArr.map((item) => {
          return (
            <div
              key={item}
              className={`${
                this.state.paginationNum === item
                  ? "pagination-number selected-pagination-number"
                  : item !== "..."
                  ? "pagination-number"
                  : "pagination-dots"
              }`}
              onClick={(e) => this.handlePagination(e)}
            >
              {item}
            </div>
          );
        });
      }
      paginationSection = (
        <div className="pagination-container">{paginationContent}</div>
      );
    }

    return (
      <div className="search-page">
        {isWindow && (
          <>
            <Helmet>
              <title>Search | GSPANN</title>
              <meta name="robots" content="noindex, nofollow" />
              <meta http-equiv="Pragma" content="no-cache" />
              <meta http-equiv="Expires" content="0" />
              <meta
                name="google-site-verification"
                content="Fy8VROwdCIk4KmBbEnJGko3-ZXm9agz417MGKGPUBP8"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link rel="canonical" href={pageURL} hrefLang="en" />
              <link rel="alternate" href={pageURL} hrefLang="en" key="en" />

              {this.searchSchema}
            </Helmet>
            <Header
              menuData={prismicHeromenu.data.nav}
              pagesLinksData={allPrismicPage.edges.concat(
                allPrismicResource.edges
              )}
            />
            <Container className="searchcontainer">
              <Row noGutters>
                <Col>
                  <input
                    type="text"
                    id="searchBox2"
                    autoComplete="off"
                    className="ss360SearchBox"
                    placeholder="What are you looking for?"
                    onChange={(e) => {
                      this.setState({ searchVal: e.target.value });
                      if (e.target.value.length < 3) {
                        this.setState({ disableSearch: true });
                      } else {
                        this.setState({ disableSearch: false });
                      }
                    }}
                    onKeyUp={(e) => this.handleSearchEnter(e)}
                    value={this.state.searchVal || ""}
                    ref={(inputElement) => {
                      // constructs a new function on each render
                      if (inputElement) {
                        inputElement.focus();
                      }
                    }}
                  />
                  <Button
                    color="primary"
                    size="sm"
                    className="search-btn ss360Button"
                    onClick={this.searchData}
                    disabled={this.state.disableSearch ? true : false}
                  >
                    {" "}
                    Search
                  </Button>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div
                    id="loader-section"
                    style={{
                      display: this.state.ShowLoading ? "block" : "none",
                    }}
                  >
                    <div className="detail-page-loading loading">
                      <div className="loader" />
                    </div>
                  </div>
                  <main role="main" id="main-searchcontainer">
                    <div className="filter-container">
                      <div className="searchResultCount">
                        {this.state.searchData.totalResults} Results for "
                        {this.state.searchData.query}"
                      </div>
                      <div className="pagination-seation top-pagination">
                        <div className="loadMoreBtnContainer">
                          <button
                            name="previous"
                            onClick={this.loadMoreResults}
                            className={`loadMoreResultBtn ${
                              this.state.paginationNum === 1
                                ? "disable-btn"
                                : ""
                            }`}
                            disabled={
                              this.state.paginationNum === 1
                                ? "disabled"
                                : false
                            }
                          >
                            <i className="fas fa-angle-left"></i>
                            Previous
                          </button>
                        </div>
                        {paginationSection}
                        <div className="loadMoreBtnContainer">
                          <button
                            name="next"
                            onClick={this.loadMoreResults}
                            className={`loadMoreResultBtn ${
                              this.state.paginationNum !== paginationNum
                                ? ""
                                : "disable-btn"
                            }`}
                            disabled={
                              this.state.paginationNum !== paginationNum
                                ? false
                                : "disabled"
                            }
                          >
                            Next
                            <i className="fas fa-angle-right"></i>
                          </button>
                        </div>
                      </div>
                      <div className="sort-continer">
                        Sort by:
                        <div
                          className={`most-revelent-sort ${
                            this.state.sortOption === "Most Relevant"
                              ? "selected-sort"
                              : ""
                          }`}
                          onClick={(e) => this.sortTheResults("Most Relevant")}
                        >
                          Most Relevant
                        </div>
                        <div>|</div>
                        <div
                          className={`most-recent-sort ${
                            this.state.sortOption === "Most Recent"
                              ? "selected-sort"
                              : ""
                          }`}
                          onClick={(e) => this.sortTheResults("Most Recent")}
                        >
                          Most Recent
                        </div>
                      </div>
                    </div>
                    {result}
                    <div className="pagination-seation">
                      <div className="loadMoreBtnContainer">
                        <button
                          name="previous"
                          onClick={this.loadMoreResults}
                          className={`loadMoreResultBtn ${
                            this.state.paginationNum === 1 ? "disable-btn" : ""
                          }`}
                          disabled={
                            this.state.paginationNum === 1 ? "disabled" : false
                          }
                        >
                          <i className="fas fa-angle-left"></i>
                          Previous
                        </button>
                      </div>
                      {paginationSection}
                      <div className="loadMoreBtnContainer">
                        <button
                          name="next"
                          onClick={this.loadMoreResults}
                          className={`loadMoreResultBtn ${
                            this.state.paginationNum !== paginationNum
                              ? ""
                              : "disable-btn"
                          }`}
                          disabled={
                            this.state.paginationNum !== paginationNum
                              ? false
                              : "disabled"
                          }
                        >
                          Next
                          <i className="fas fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </main>
                </Col>
              </Row>
            </Container>
            <Footer
              footerData={prismicFooter.data}
              pagesLinksData={allPrismicPage.edges.concat(
                allPrismicResource.edges
              )}
            />
          </>
        )}
      </div>
    );
  }
}

export default SearchPage;

export const pageQuery = graphql`
  query errorQueryAndErrorQuery {
    prismicHeromenu {
      id
      data {
        display_name {
          html
        }
        nav {
          primary {
            label {
              html
              text
            }
            link {
              url
            }
            label_font_color
            label_font_color_on_hover
            nav_item_background_color
            sub_link_separator_line_color
            sub_link_sub_text_font_color
            sub_link_type_font_color
            sub_nav_link_label_font_color
            nav_column_count
            nav_item_position
          }
          items {
            sub_nav_column_position
            sub_nav_link_lable {
              text
            }
            sub_nav_link {
              url
            }
            sub_link_sub_text {
              text
            }
            sub_link_type {
              text
            }
          }
        }
      }
    }
    allPrismicPage {
      edges {
        node {
          id
          uid
          data {
            page_display_name {
              text
            }
            page_path {
              text
            }
          }
        }
      }
    }
    allPrismicResource(
      filter: { data: { page_path: { text: { ne: "campaign" } } } }
    ) {
      edges {
        node {
          uid
          data {
            resource_display_name {
              text
            }
            page_path {
              text
            }
            body {
              __typename
              ... on PrismicResourceBodyPageOverview {
                primary {
                  page_thumbnail {
                    url
                    alt
                  }
                }
              }
              ... on PrismicResourceBodyResourceOverview {
                primary {
                  resource_type
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicFooter {
      data {
        footer_background_color
        footer_font_color
        address {
          text
        }
        copyright_text {
          text
        }
        footer_logo {
          url
        }
        body {
          __typename
          ... on PrismicFooterBodySocialConnection {
            id
            slice_type
            primary {
              social_connection_type {
                text
              }
              social_connection_font_color
            }
            items {
              social_icon
              social_icon_font_color
              social_icon_link {
                url
              }
            }
          }
          ... on PrismicFooterBodyGspannPolicy {
            id
            slice_type
            items {
              policy_name {
                text
              }
              policy_link {
                url
              }
              policy_font_color
            }
          }
          ... on PrismicFooterBodyFooterMenu {
            id
            slice_type
            primary {
              footer_menu_type {
                text
              }
              footer_menu_link {
                url
              }
              menu_type_font_color
            }
            items {
              footer_menu_text {
                text
              }
              footer_menu_link {
                url
              }
              footer_menu_font_color
            }
          }
        }
      }
    }
  }
`;

// export default props => (
//   <StaticQuery
//     query={`}
//     render={data => typeof window !== "undefined" && window && <SearchPage data={data} {...props} />}
//   />
// );
